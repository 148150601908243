import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import i18n from '@i18n';
import { Alert, DataGrid } from '@components/atoms';
import { GridToolbar } from '@material-ui/data-grid';
import { Chart } from 'react-google-charts';
import { Grid, LinearProgress } from '@material-ui/core';
import { useReports } from '@hooks';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import Filtros from './Filtros';

const EnsayosByDesviacion = () => {
  const { pages: { employees: { reporteria: SubReportsTexts } } } = i18n;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { getEnsayosByDesviacion, reportError, isReportLoading } = useReports();
  const [report, setReport] = useState(null);
  const [datagridCompleted, setDatagridCompleted] = useState([]);
  const [filters, setFilters] = useState({
    dateInit: moment().format('YYYY-MM-DD'),
    dateEnd: moment().format('YYYY-MM-DD'),
    all: false,
    archivados: false,
    generate: false,
  });

  useEffect(async () => {
    if (filters.generate) {
      if (moment(filters.dateEnd).isBefore(filters.dateInit) && !filters.all) {
        enqueueSnackbar('La fecha de inicio no puede ser mayor a la fecha final', { variant: 'error' });
      } else {
        const result = await getEnsayosByDesviacion(filters);
        setReport(result);
        const dataAdelantados = result.groupedData.adelantado.map((row) => ({ ...row, desviacion: 'Adelantado' }));
        const dataAtrasados = result.groupedData.atrasados.map((row) => ({ ...row, desviacion: 'Atrasado' }));
        const dataCumpliendo = result.groupedData.cumpliendo.map((row) => ({ ...row, desviacion: 'Cumpliendo' }));
        const dataEjecAdelantados = result.groupedDataEjecucion.adelantado.map((row) => ({ ...row, desviacion: 'Adelantados', revisionDate: '' }));
        const dataEjeAtrasados = result.groupedDataEjecucion.atrasados.map((row) => ({ ...row, desviacion: 'Atrasado', revisionDate: '' }));
        const dataEjeCumpliendo = result.groupedDataEjecucion.cumpliendo.map((row) => ({ ...row, desviacion: 'Cumpliendo', revisionDate: '' }));

        setDatagridCompleted([...dataAdelantados, ...dataAtrasados, ...dataCumpliendo, ...dataEjeAtrasados, ...dataEjecAdelantados, ...dataEjeCumpliendo]);
      }
    }
    setFilters({ ...filters, generate: false });
  },
  [filters.generate]);

  const groupDataComplete = report && report.groupedData && [
    ['Desviación', 'Cantidad'],
    ['Atrasados', report.groupedData.atrasados.length],
    ['A tiempo', report.groupedData.cumpliendo.length],
    ['Adelantados', report.groupedData.adelantado.length],
  ];

  const groupDataEjecutados = report && report.groupedDataEjecucion && [
    ['Desviación', 'Cantidad'],
    ['Atrasados', report.groupedDataEjecucion.atrasados.length],
    ['A tiempo', report.groupedDataEjecucion.cumpliendo.length],
    ['Adelantados', report.groupedDataEjecucion.adelantado.length],
  ];

  const options = {
    title: 'Redacción - Liberación',
  };
  const options2 = {
    title: 'Laboratorio',
  };

  const columnsCompleted = [
    { field: 'desviacion', headerName: 'Desviación', flex: 0.5 },
    {
      field: 'number',
      headerName: 'Análisis',
      flex: 0.7,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'customer',
      headerName: 'Cliente',
      flex: 1.2,
      valueGetter: (values) => values.row.customer.name,
    },
    {
      field: 'step',
      headerName: 'Estado',
      flex: 0.6,
      valueFormatter: ({ value }) => value[0].toUpperCase() + value.substring(1),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'executeDate',
      headerName: 'Limite Análisis',
      flex: 0.7,
      valueFormatter: ({ value }) => moment(value).add(1, 'day').format('DD-MM-yyyy'),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'ejecucionDate',
      headerName: 'Real Análisis',
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        if (row.ejecucionDate) {
          const fecha = moment(row.ejecucionDate).add(1, 'day').format('DD-MM-yyyy');
          if (moment(row.executeDate).add(1, 'day').isBefore(row.ejecucionDate)) {
            return <div style={{ color: 'red' }}>{fecha}</div>;
          }
          if (moment(row.executeDate).add(1, 'day').isAfter(row.ejecucionDate)) {
            return <div style={{ color: 'blue' }}>{fecha}</div>;
          }
          return <div>{fecha}</div>;
        }
        return '';
      },
    },
    {
      field: 'limitDate',
      headerName: 'Limite Entrega',
      flex: 0.6,
      // valueFormatter: ({ value }) => `${moment(value).day()}-${moment(value).month()}-${moment(value).year()} }`,
      valueFormatter: ({ value }) => moment(value).add(1, 'day').format('DD-MM-yyyy'),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'redaccionDate',
      headerName: 'Real Completado',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        if (row.redaccionDate) {
          const fecha = moment(row.redaccionDate).format('DD-MM-yyyy');
          if (moment(row.limitDate).add(1, 'day').isBefore(row.redaccionDate)) {
            return <div style={{ color: 'red' }}>{fecha}</div>;
          }
          if (moment(row.limitDate).add(1, 'day').isAfter(row.redaccionDate)) {
            return <div style={{ color: 'blue' }}>{fecha}</div>;
          }
          return <div>{fecha}</div>;
        }
        return '';
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>{`${i18n.appName} - ${SubReportsTexts.subreports.ensayosbydesviacion.title}`}</title>
      </Helmet>
      <Filtros filters={filters} setFilters={setFilters} />
      {reportError && <Alert>{reportError.message}</Alert>}
      {isReportLoading && <LinearProgress />}
      {report && (
        <Grid container spacing={0} classes={classes.container} style={{ textAlign: 'center' }}>
          <Grid item md={6} xs={12}>
            <Chart
              chartType="PieChart"
              width="100%"
              height="400px"
              data={groupDataEjecutados}
              options={options2}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Chart
              chartType="PieChart"
              width="100%"
              height="400px"
              data={groupDataComplete}
              options={options}
            />
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rowsPerPage={10}
              rowHeight={25}
              columns={columnsCompleted}
              rows={datagridCompleted}
              key="id"
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mt1: {
    marginTop: '1em',
  },
  container: {
    padding: theme.spacing(3),
  },
  changeContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  disabledButton: {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
}));

EnsayosByDesviacion.propTypes = {
};

EnsayosByDesviacion.defaultProps = {
};

export default EnsayosByDesviacion;
