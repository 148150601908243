import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ButtonAdorned, DatePicker, Divider,
} from '@components/atoms';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Grid, Checkbox, FormControlLabel, FormControl,
} from '@material-ui/core';
import moment from 'moment';

const Filtros = ({ filters, setFilters }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Grid container spacing={0} classes={classes.container}>
        <Grid item md={1} xs={12} style={{ textAlign: 'center', alignSelf: 'center' }}>
          <ButtonAdorned onClick={() => setFilters({ ...filters, generate: true })} disabled={filters.generate} classes={classes.disabledButton}>
            Generar
          </ButtonAdorned>
        </Grid>
        <Grid item md={2} xs={12}>
          <FormControl fullWidth>
            <DatePicker
              label="Fecha Inicio"
              name="dateInit"
              value={filters.dateInit}
              disabled={filters.all || filters.generate}
              onChange={(date) => setFilters({ ...filters, dateInit: date.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item md={2} xs={12}>
          <DatePicker
            label="Fecha Fin"
            name="dateEnd"
            value={filters.dateEnd}
            disabled={filters.all || filters.generate}
            onChange={(date) => setFilters({ ...filters, dateEnd: date.target.value })}
          />
        </Grid>
        <Grid item md={1} xs={12} style={{ textAlign: 'left', alignSelf: 'center', maxWidth: '6rem' }}>
          <FormControlLabel
            control={<Checkbox onChange={(e) => setFilters({ ...filters, all: e.target.checked })} name="all" disabled={filters.generate} />}
            label="Todas"
          />
        </Grid>
        <Grid item md={2} xs={12} style={{ textAlign: 'center', alignSelf: 'center' }}>
          <FormControlLabel
            control={<Checkbox onChange={(e) => setFilters({ ...filters, archivados: e.target.checked })} name="archivados" disabled={filters.generate} />}
            label="Mostrar Archivados"
          />
        </Grid>
        {(history.location.pathname.match(/ensayosbylaboratorista/g) !== null || history.location.pathname.match(/muestrasbylab/g) !== null) && (
        <Grid item md={2} xs={12} style={{ textAlign: 'left', alignSelf: 'center' }}>
          <FormControlLabel
            control={<Checkbox onChange={(e) => setFilters({ ...filters, ejecucion: e.target.checked })} name="ejecucion" disabled={filters.generate} />}
            label="En Ejecución"
          />
        </Grid>
        )}
      </Grid>
      <Divider />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mt1: {
    marginTop: '1em',
  },
  container: {
    padding: theme.spacing(3),
  },
  changeContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  disabledButton: {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
}));

Filtros.propTypes = {
  filters: PropTypes.shape({
    dateInit: PropTypes.string,
    dateEnd: PropTypes.string,
    all: PropTypes.bool,
    archivados: PropTypes.bool,
    generate: PropTypes.bool,
    ejecucion: PropTypes.bool,
  }),
  setFilters: PropTypes.func.isRequired,
};

Filtros.defaultProps = {
  filters: {
    dateInit: moment().format('YYYY-MM-DD'),
    dateEnd: moment().format('YYYY-MM-DD'),
    all: false,
    archivados: false,
    generate: false,
    ejecucion: false,
  },
};

export default Filtros;
